@import "collapsibleItem";
@import "~base/components/toastMessage";

footer {
    .footer-container {
        .footer-title {
            font-style: normal;
            font-weight: $font-weight-heavy;
            font-size: 1rem;
        }

        .footer--contact-us__style {
            font-weight: $font-weight-heavy;
        }
    }

    .footer-back {
        background-color: $black;
    }
}

@include media-breakpoint-down(sm) {
    footer {
        .footer-container {
            .footer-title {
                font-size: .875rem;
            }

            .footer-content {
                font-size: .875rem;
            }
        }

        .footer-item {
            padding-top: 2rem;
            padding-bottom: 2rem;
            border-bottom: 0.5px solid $grey-75;
        }

        .store {
            padding-top: 2rem;
            padding-bottom: 2rem;
            border-bottom: 0.5px solid $grey-75;
        }
    }
}

footer {
    background-color: #F6F6F6;

    .fa-brands {
        color: $white;
    }

    h2 {
        font-size: $font-size-base;
        margin-bottom: 0;
        line-height: 2.5; /* 40/16 */
    }

    ul {
        list-style: none;
        margin: 0;
        padding-left: 0;
    }

    .social {
        h2 {
            margin-top: 0;
        }

        @include clearfix;
    }

    .footer-container .footer-item.collapsible-xs button {
        padding: 0;
    }

    .social-links {

        @include clearfix;

        @include media-breakpoint-down(xs) {
            width: 80%;
        }

        float: left;

        li {
            float: left;
            margin: 0.313em;

            @include media-breakpoint-down(xs) {
                width: 20%;
                text-align: center;
            }
        }

        a {
            font-size: 2.25em;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .content {
        li {
            height: 1.875rem;
        }
    }

    .copyright,
    .postscript {
        font-size: 0.8125em;
    }

    .back-to-top {
        margin: 0.1em 0.313em;
        padding: 0;
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;

        i {
            &.fa-arrow-up {
                color: #FFF;
            }

            &.fa-circle {
                text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.footer-item {

    @include media-breakpoint-down(xs) {
        border-bottom: 1px solid $dark-gray;
    }
}

.email-signup-message {
    @include toast-message();
}

.email-signup-alert {
    @include toast-alert();
}
