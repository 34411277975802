.container-tracking {
    z-index: $z-index-tracking-consent;
    position: fixed;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.91);
    color: $black;
    width: 100vw;
    padding-right: 15px;

    .description {
        font-size: 0.875rem;
    }
}
