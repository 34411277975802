@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "./components/toastMessage";

body {
    color: $black;
    background-color: $white;
    font-family: $base-font-family;
    font-style: normal;
    position: relative;
}

.b1 {
    font-size: 1.125rem;
    line-height: 1.75;
}

.b2 {
    font-size: 1rem;
    line-height: 1.5;
}

.b3 {
    font-size: .875rem;
    line-height: 1.25;
}

a {
    font-size: inherit;
    font-weight: $base-font-weight;
    color: inherit;
    text-decoration: underline;

    &.a--underline__removed {
        text-decoration: none;
    }

    &:hover {
        color: inherit;
        text-decoration: underline;
    }

    &.focus,
    &:focus {
        outline: 0.5px dashed $black;
        text-decoration: underline;
    }

    &.disabled,
    &:disabled {
        color: $grey-75;
        pointer-events: none;
        text-decoration: none;
    }

    &.error,
    &.invalid,
    &:invalid {
        color: #A4161A;
    }

    &.grey-75 {
        color: $grey-75;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &.grey-d9 {
        color: $grey-d9;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &.grey-f2 {
        color: $grey-f2;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

span {
    a {
        &.btn {
            &.btn-link {
                padding: 0;
                font-size: inherit;
                line-height: inherit;
                border-radius: 0;
                color: inherit;
            }
        }
    }
}

.text-black {
    color: $black;
}

.text-grey-75 {
    color: $grey-75;
}

.text-grey-d9 {
    color: $grey-d9;
}

.text-grey-f2 {
    color: $grey-f2;
}

.carousel {
    .icon-prev,
    .icon-next {
        background-color: transparent;
        font-size: 1.875em;
        height: 3rem;
        padding-top: 0.24em;
        width: 3rem;

        &:before {
            color: black;
            font-family: 'FontAwesome';
        }
    }

    .icon-prev {
        &:before {
            font-family: 'Font Awesome\ 6 Pro';
            content: '\F053';
        }
    }

    .icon-next {
        &:before {
            font-family: 'Font Awesome\ 6 Pro';
            content: '\F054';
        }
    }

    .carousel-control-prev {
        text-decoration: none;
        justify-content: flex-start;
    }

    .carousel-control-next {
        text-decoration: none;
        justify-content: flex-end;
    }
}

.nav-tabs {
    border-bottom: $border-width solid $black;

    .nav-link {
        font-size: 1rem;
        color: $nav-tabs-link-hover-border-color;

        &.active {
            border-bottom: 0.188em solid $black;
            font-weight: bold;
        }
    }
}

.nav-fill .nav-item {
    flex: 1 1;
}

.card {
    margin-bottom: 1.5em;
    border: none;
}

.card-header h4 {
    margin-bottom: 0;
}

.modal .modal-body {
    flex: 0 0 auto;
}

dt {
    color: $gray-700;
    font-weight: normal;
}

.custom-checkbox {
    &.custom-control {
        padding-left: 1.9rem;
    }

    .custom-control-label {
        &:before {
            top: 0.1rem;
            left: -1.8rem;
            width: 1.25rem;
            height: 1.25rem;
            border: 0.5px solid $black;
            background-color: transparent;
            border-radius: 0;
        }

        &:after {
            left: -1.7rem;
        }
    }

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &:after {
                    background-image: url('../images/icons/check.svg');
                    padding: 3px;
                }

                &:before {
                    color: transparent;
                    border: 0.5px solid $black;
                    background-color: transparent;
                }
            }
        }

        &:focus {
            ~ .custom-control-label {
                &:before {
                    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
                }
            }
        }
    }
}

.custom-radio .custom-control-label:before {
    border: 1px solid $black;
    background: $grey3;
    background: linear-gradient($grey3, $grey5);
}

.form-group {
    &.form-group-password {
        position: relative;

        .password-show {
            position: absolute;
            top: 2rem;
            right: 0;
        }
    }
}

.form-control {
    color: $grey-75;
    border: none;
    border-bottom: 1px solid $grey-75;
    border-radius: 0;
    background-color: transparent;

    &.is-invalid {
        &::placeholder {
            color: #A4161A;
        }

        ~ .invalid-feedback {
            display: block;
        }
    }

    &:hover {
        color: $black;
        border-bottom: 1px solid $black;

        &::placeholder {
            color: $black;
        }
    }

    &:focus,
    &:active {
        outline: 0;
        color: $black;
        border-color: none;
        border-bottom: 1px solid $black;
        box-shadow: none;
    }

    &::placeholder {
        color: $grey-75;
    }

    .password-show {
        ~ .invalid-feedback {
            display: block;
        }
    }
}

.h1,
h1 {
    font-size: 3.5rem;
    font-style: normal;
    font-weight: $base-font-weight;
}

.h2,
h2 {
    font-size: 3rem;
    font-style: normal;
    font-weight: $base-font-weight;
}

.h3,
h3 {
    font-size: 2.5rem;
    font-style: normal;
    font-weight: $base-font-weight;
}

.h4,
h4 {
    font-size: 2rem;
    font-style: normal;
    font-weight: $base-font-weight;
}

.h5,
h5 {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: $base-font-weight;
}

.h6,
h6 {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: $base-font-weight;
}

.h7 {
    font-size: 1rem;
    font-style: normal;
    font-weight: $base-font-weight;
}

.btn {
    font-style: normal;
    display: inline-block;
    color: $black;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &.focus,
    &:focus {
        outline: 0;
        box-shadow: none;
    }

    &.disabled:not(.btn-dark):not(.btn-link),
    &:disabled:not(.btn-dark):not(.btn-link) {
        opacity: .65;
    }

    &.btn-dark {
        font-weight: $font-weight-huskie;
        color: $white;
        background-color: $black;
        text-decoration: none;

        &:hover {
            background-color: #545454;
        }

        &.focus,
        &:focus {
            outline: $dark dashed 1px;
            outline-offset: 2px;
        }

        &.error,
        &.invalid,
        &:invalid {
            color: $dark;
            background-color: $grey-d9;
        }

        &.disabled,
        &:disabled {
            color: $black;
            background-color: $grey-d9;
            border-color: $grey-d9;
            opacity: 1;
        }
    }

    &.btn-primary {
        font-weight: $font-weight-huskie;
        color: $black;
        background-color: $white;
        border: 1px solid $white;
        padding: 8px;
        text-decoration: none;

        &:hover {
            background-color: $grey-f2;
            border: 1px solid $white;
            text-decoration: underline;
        }

        &.active,
        &:active {
            background-color: $white;
            font-weight: $font-weight-heavy;
        }

        &.focus,
        &:focus {
            outline: $dark solid 0.5px;
            outline-offset: 2px;
        }

        &.disabled,
        &:disabled {
            color: #6E6E6E;
            border: 1px solid $grey-d9;
            background-color: $grey-f2;
        }

        &.error,
        &.invalid,
        &:invalid {
            color: #A4161A;
            border: 1px solid #A4161A;
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active {
            color: $white;
            background-color: $black;
            border-color: $black;
        }

        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }
    }

    &.btn-outline-primary {
        font-weight: $font-weight-huskie;
        color: $black;
        background-color: $white;
        border: 1px solid $black;
        padding: 8px;
        text-decoration: none;

        &:hover {
            background-color: $black;
            border: 1px solid $black;
            color: $white;
        }

        &.active,
        &:active {
            background-color: $white;
            font-weight: $font-weight-heavy;
        }

        &.focus,
        &:focus {
            outline: $dark dashed 1px;
            outline-offset: 2px;
        }

        &.disabled,
        &:disabled {
            color: #6E6E6E;
            border: 1px solid $grey-d9;
            background-color: $grey-f2;
        }

        &.error,
        &.invalid,
        &:invalid {
            color: #A4161A;
            border: 1px solid #A4161A;
        }

        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active {
            color: $white;
            background-color: $black;
            border-color: $black;
        }

        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
        }
    }

    &.btn-link {
        color: $black;
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }

        &.active,
        &:active {
            box-shadow: none;
        }

        &.focus,
        &:focus {
            outline: 1px dashed;
            text-decoration: underline;
        }

        &.disabled,
        &:disabled {
            color: $grey-75;
            pointer-events: none;
            text-decoration: none;
        }

        &.error,
        &.invalid,
        &:invalid {
            color: #A4161A;
        }

        &[aria-current="page"] {
            text-decoration: none;
        }
    }
}

.custom-form-check-input {
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid $black;
    background-clip: content-box;
    padding: 2px !important;

    &:checked {
        background-color: $black;
    }
}

.show > .btn-outline-primary.dropdown-toggle {
    color: $white;
    background-color: $black;
    border-color: $black;
}

.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.custom-select {
    border: 1px solid $grey-400;
    border-radius: .25rem;
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1rem;
    color: $black;
    padding: .5rem 1rem .5rem .5rem;

    &:hover,
    &:focus,
    &:active {
        color: $black;
        border: 1px solid $black;
        box-shadow: none;
    }

    &:focus {
        outline: .5px dashed $black;
    }

    &:disabled {
        color: $grey-d9;
        background-color: transparent;
        border-bottom: .5px solid $grey-d9;
    }

    &.is-invalid {
        color: $red;
    }

    &.shippingMethods {
        background-position-x: 98%;
    }
}

.breadcrumb-item {
    &:not(:last-child) {
        text-decoration: underline;
    }

    &:not(:last-child):after {
        display: inline-block;
        padding-left: .5rem;
        color: $black;
        content: ">";
        text-decoration: underline;
    }

    a {
        text-transform: uppercase;
    }
}

.breadcrumb-item + .breadcrumb-item:before {
    display: none;
}

.clearfix .close:after {
    font: var(--fa-font-regular);
    content: "\f00d"; // Font Awesome x-mark
    font-size: 1.5rem;
}

.align-items-center {
    align-items: center;
}

.close:after {
    content: url('../images/icons/close-minor.svg');
}

.send-email-btn {
    .btn {
        padding: 8px;
    }
}

.modal {
    z-index: $z-index-modal;

    &[aria-labelledby="requestPasswordResetModal"] {
        .modal-header {
            border-bottom: none;
        }
    }
}

table {
    &.table-sm,
    &.table-sm {
        th:not(:first-child),
        td:not(:first-child) {
            padding: .4rem;
        }
    }
}

@include media-breakpoint-up(sm) {
    table {
        &.table-sm,
        &.table-sm {
            th:not(:first-child),
            td:not(:first-child) {
                padding: .4rem;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    table {
        &.table-md,
        &.table-md {
            th:not(:first-child),
            td:not(:first-child) {
                padding: 1rem;
            }
        }
    }
}

.d-flex-direction-row {
    display: flex;
    flex-direction: row;
}

@include media-breakpoint-up(xs) {
    .justify-content-space-between-xs {
        justify-content: space-between !important;
    }
}

@include media-breakpoint-up(xl) {
    .justify-content-end-xl {
        justify-content: flex-end !important;
    }
}

@include media-breakpoint-down(lg) {
    .collapsible-xl {
        margin: 0;
    }
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    opacity: .7;
    border-radius: .125rem;
}

.skeleton-text {
    width: 100%;
    margin-bottom: .25rem;
    border-radius: .125rem;

    &:last-child {
        width: 80%;
        margin-bottom: 0;
    }
}

.skeleton-text {
    width: 100%;
    margin-bottom: .25rem;
    border-radius: .125rem;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

@include media-breakpoint-down(sm) {
    .text-sm-center-down {
        text-align: center;
    }
}

@include media-breakpoint-down(md) {
    .text-md-center-down {
        text-align: center;
    }
}

.form-group {
    position: relative;

    .form-control-label {
        font-family: $base-font-family;
        font-style: normal;
        font-weight: $base-font-weight;
        line-height: 1;
        font-size: 0.875rem;
        color: $grey-75;

        &.form-control-label {
            &:focus,
            &:active {
                font-size: 0.875rem;
                color: $black;

                + .form-control {
                    height: auto !important;
                    padding-bottom: 0.3rem !important;
                    border-bottom: 1px solid $black !important;
                }
            }
        }
    }

    &.required {
        .form-control-label:before {
            content: none !important;
        }

        .form-control-label:after {
            content: "*";
            color: $grey-75;
        }
    }

    .form-control {
        &.form-password {
            &[type="password"] + .password-show::after {
                content: url('../images/icons/show.svg');
                display: flex;
            }

            &[type="text"] + .password-show::after {
                content: url('../images/icons/passwordRevealed.svg');
                display: flex;
            }
        }

        &#password,
        &#email {
            display: flex;
            justify-content: space-between;
        }

        &.is-invalid {
            border-bottom: 0.5px solid #A4161A !important;

            &.coupon-code-field {
                background-position: 97%calc(0.375em + 0.25rem);
            }
        }

        &:placeholder-shown {
            font-size: 1rem;
        }

        &:disabled {
            color: $grey-d9;
            border: 0.5px solid $grey-75;
        }
    }
}

.update-messages {
    @include toast-message();

    z-index: 300;
}

.data-updated {
    @include toast-alert();
}

.alert-dark,
.alert-success {
    color: $white;
    background-color: $grey-75;
    border-color: $grey-75;

    &::before {
        font-family: 'Font Awesome 6 Pro';
        font-weight: 300;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        line-height: 1;
        text-rendering: auto;
        margin-right: 0.5rem;
        content: '\F00C';
    }
}

.alert-error,
.alert-danger {
    color: $white !important;
    background-color: #A4161A !important;
    border-color: #A4161A !important;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.card-body-border {
    border: 0.5px solid $grey-75;
    border-radius: 0;
    background: transparent;
}

.dropdown-toggle {
    &::after {
        border: none;
    }
}

.h-8 {
    height: 4.5rem;
}

.h-6 {
    height: 2.3rem;
}

.pt-2-5 {
    padding-top: 2.5rem;
}

.custom-text-area {
    border: 1px solid $grey-d9;
    padding: 1rem;

    &:hover,
    &:focus,
    &:active {
        color: $black;
        border-color: $black;
        box-shadow: none;
    }

    &:focus {
        outline: .5px dashed $black;
    }

    &:disabled {
        color: $grey-d9;
        background-color: transparent;
        border-bottom: .5px solid $grey-d9;
    }

    &.is-invalid {
        color: $red;
        border: 1px solid $red;
    }
}

.fa-regular,
.fa {
    color: $fa-color;
}

.p-unset {
    position: unset !important;
}

.p-relative {
    position: relative !important;
}

.logo-maintenance {
    img {
        height: 4.375rem;
    }

    @include media-breakpoint-down(sm) {
        img {
            height: 3.125rem;
        }
    }
}

.modal-backdrop {
    z-index: $z-index-modal-backdrop;
}

.overlay {
    background-color: #000;
    display: none;
    height: 100%;
    opacity: .4;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-index-overlay;
}

.grecaptcha-bagde {
    visibility: hidden;
}

.mobile-global-action {
    ul {
        list-style: none;
        padding-left: 0;
    }
}

.check-item {
    width: 1.25rem !important;
    height: 1.25rem;
    border: 0.5px solid #000;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:checked {
        content: url('../images/icons/check.svg');
    }
}
